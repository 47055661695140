<template>
  <div>
    <header class="flex header">
      <nav class="flex custom-container justify-between py-5">
        <div class="flex">
          <router-link
            class="btn w-56 -ml-12"
            style="color: #274FED;"
            :to="{ name: 'login' }"
          >
            <img :src="logo" alt="CredPal" class="" />
          </router-link>
        </div>
        <div
          class="flex w-full md:w-3/10 justify-end md:justify-between md:mr-12 md:mt-4"
        >
          <template v-if="!checkAuth">
            <div
              class="font-normal text-blue-500 cursor-pointer hidden md:block md:mr-4"
            >
              <router-link class="" :to="{ name: 'login' }">
                Sign In</router-link
              >
            </div>
            <div class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0">
              <router-link class="" :to="{ name: 'signup' }"
                >Get Started</router-link
              >
            </div>
          </template>
          <div
            v-else
            class="font-normal text-blue-500 cursor-pointer mt-4 md:mt-0 ml-auto"
          >
            <router-link class="" :to="{ name: 'dashboard' }"
              >Dashboard</router-link
            >
          </div>
        </div>
      </nav>
    </header>
    <section class="section" style="padding-bottom: 50px;">
      <div class="custom-container">
        <div class="md:w-6/10 mx-auto">
          <h1 class="mb-6 text-4xl md:text-5xl font-black leading-tight">
            TERMS & CONDITIONS
          </h1>
        </div>
        <div class="md:w-6/10 mx-auto">
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Introduction</h2>
            <div class="leading-8">
              This website is operated by Credpal. The terms “we”, “us”, and
              “our” refer to Credpal. The use of our website and services is
              subject to the following terms and conditions of use, as may be
              amended from time to time (the “Terms”). The Terms are to be read
              together by you with any terms, conditions or disclaimers provided
              in the pages of our website. Please review the Terms carefully.
              The Terms apply to all users of our website, including without
              limitation, users who are browsers, customers, merchants, vendors
              and/or contributors of content. If you access and use this website
              and our services, you accept and agree to be bound by and comply
              with the Terms and our Privacy Policy. If you do not agree to the
              Terms or our Privacy Policy, you are not authorized to access our
              website, use any of our website’s services or place an order on
              our website.
            </div>
          </div>

          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Use of our Website</h2>
            <div class="leading-8">
              You agree to use our website and services for legitimate purposes
              and not for any illegal or unauthorized purpose, including without
              limitation, in violation of any intellectual property or privacy
              law. By agreeing to the Terms, you represent and warrant that you
              are at least the age of majority in your state or province of
              residence and are legally capable of entering into a binding
              contract. You agree to not use our website to conduct any activity
              that would constitute a civil or criminal offense or violate any
              law. You agree not to attempt to interfere with our website’s
              network or security features or to gain unauthorized access to our
              systems. You agree to provide us with accurate personal
              information, such as your email address, mailing address and other
              contact details in order to complete your order or contact you as
              needed. You agree to promptly update your account and information.
              You authorize us to collect and use this information to contact
              you in accordance with our Privacy Policy.
            </div>
          </div>

          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              General Conditions
            </h2>
            <div class="leading-8">
              We reserve the right to refuse service to anyone, at any time, for
              any reason. We reserve the right to make any modifications to the
              website, including terminating, changing, suspending or
              discontinuing any aspect of the website at any time, without
              notice. We may impose additional rules or limits on the use of our
              website. You agree to review the Terms regularly and your
              continued access or use of our website will mean that you agree to
              any changes. You agree that we will not be liable to you or any
              third party for any modification, suspension or discontinuance of
              our website or for any service, content, feature or product
              offered through our website. You may not use any of the Services
              and you may not accept this Agreement if you do not have the legal
              capacity to accept and be bound by these terms, this also means
              you must be of legal age, at least 18 years of age to form a
              binding contract with CredPal. Before you continue, you should
              print or save a copy of this Agreement for your records.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Fees and Charges</h2>
            <div class="leading-8">
              The service is subject to a finance charge based on the applicable
              interest rate. CredPal will always disclose the specific finance
              charge and repayment option. In the event of late payment, CredPal
              will charge you a late payment fee. The fee is fixed by Credpal
              and it may be revised from time.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Default
            </h2>
            <div class="leading-8">
              A default will occur when you (the User) fails to repay the sum
              allocated to him/her at the due date of payment and after
              notifications have been sent to them to make payment. Default also
              occurs where the information you supplied to us are discovered to
              be false, and where CredPal’s right becomes prejudice, thereby
              causing CredPal damage or loss.
              <p>
                In the event of default in payment, CredPal has the following
                rights:
              </p>
              <ol class="list-decimal ml-8  leading-8">
                <li class="pl-8">
                  CredPal will notify the Credit Bureau of your default.
                </li>
                <li class="pl-8">
                  CredPal may institute legal proceedings against you and is
                  under no obligation to inform you before the proceedings
                  commence.
                </li>
                <li class="pl-8">
                  You shall be responsible for all legal costs and expenses
                  incurred by CredPal in attempting to obtain repayment of any
                  outstanding balance owed by you. Interest on any amount which
                  becomes due and payable shall be charged.
                </li>
              </ol>
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Collection</h2>
            <div class="leading-8">
              You agree to allow CredPal to send you payment reminders from
              time-to-time. You also agree that payment reminders may take the
              form of any available communication. You also agree that if you
              fail to pay an amount owed to CredPal pursuant to this Agreement,
              CredPal may engage in collection efforts to recover such amounts
              from you. These collection efforts may involve contacting you
              directly, submitting your information to a collection’s agency, or
              taking legal action. FAILURE TO PAY MAY AFFECT YOUR CREDIT SCORE.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Links to Third-Party Websites
            </h2>
            <div class="leading-8">
              Links from or to websites outside our website are meant for
              convenience only. We do not review, endorse, approve or control,
              and are not responsible for any sites linked from or to our
              website, the content of those sites, the third parties named
              therein, or their products and services. Linking to any other site
              is at your sole risk and we will not be responsible or liable for
              any damages in connection with linking. Links to downloadable
              software sites are for convenience only and we are not responsible
              or liable for any difficulties or consequences associated with
              downloading the software. Use of any downloaded software is
              governed by the terms of the license agreement, if any, which
              accompanies or is provided with the software.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Use Comments, Feedback, and Other Submissions
            </h2>
            <div class="leading-8">
              You acknowledge that you are responsible for the information,
              profiles, opinions, messages, comments and any other content
              (collectively, the “Content”) that you post, distribute or share
              on or through our website or services available in connection with
              our website. You further acknowledge that you have full
              responsibility for the Content, including but limited to, with
              respect to its legality, and its trademark, copyright and other
              intellectual property ownership. You agree that any Content
              submitted by you in response to a request by us for a specific
              submission may be edited, adapted, modified, recreated, published,
              or distributed by us. You further agree that we are under no
              obligation to maintain any Content in confidence, to pay
              compensation for any Content or to respond to any Content. You
              agree that you will not post, distribute or share any Content on
              our website that is protected by copyright, trademark, patent or
              any other proprietary right without the express consent of the
              owner of such proprietary right. You further agree that your
              Content will not be unlawful, abusive or obscene nor will it
              contain any malware or computer virus that could affect our
              website’s operations. You will be solely liable for any Content
              that you make and its accuracy. We have no responsibility and
              assume no liability for any Content posted by you or any
              third-party. We reserve the right to terminate your ability to
              post on our website and to remove and/or delete any Content that
              we deem objectionable. You consent to such removal and/or deletion
              and waive any claim against us for the removal and/or deletion of
              your Content.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Your Personal Information
            </h2>
            <div class="leading-8">
              Please see our Privacy Policy to learn about how we collect, use,
              and share your personal information.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Errors and Omissions</h2>
            <div class="leading-8">
              Please note that our website may contain typographical errors or
              inaccuracies and may not be complete or current. We reserve the
              right to correct any errors, inaccuracies or omissions and to
              change or update information at any time, without prior notice
              (including after an order has been submitted). Such errors,
              inaccuracies or omissions may relate to product description,
              pricing, promotion, and availability and we reserve the right to
              cancel or refuse any order placed based on incorrect pricing or
              availability information, to the extent permitted by applicable
              law. We do not undertake to update, modify or clarify information
              on our website, except as required by law.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Disclaimer and Limitation of Liability
            </h2>
            <div class="leading-8">
              You assume all responsibility and risk with respect to your use of
              our website, which is provided “as is” without warranties,
              representations or conditions of any kind, either express or
              implied, with regard to information accessed from or via our
              website, including without limitation, all content and materials,
              and functions and services provided on our website, all of which
              are provided without warranty of any kind, including but not
              limited to warranties concerning the availability, accuracy,
              completeness or usefulness of content or information,
              uninterrupted access, and any warranties of title,
              non-infringement, merchantability or fitness for a particular
              purpose. We do not warrant that our website or its functioning or
              the content and material of the services made available thereby
              will be timely, secure, uninterrupted or error-free, that defects
              will be corrected, or that our websites or the servers that make
              our website available are free of viruses or other harmful
              components. The use of our website is at your sole risk and you
              assume full responsibility for any costs associated with your use
              of our website. We will not be liable for any damages of any kind
              related to the use of our website. In no event will we, or our
              affiliates, our or their respective content or service providers,
              or any of our or their respective directors, officers, agents,
              contractors, suppliers or employees be liable to you for any
              direct, indirect, special, incidental, consequential, exemplary or
              punitive damages, losses or causes of action, or lost revenue,
              lost profits, lost business or sales, or any other type of damage,
              whether based in contract or tort (including negligence), strict
              liability or otherwise, arising from your use of, or the inability
              to use, or the performance of, our website or the content or
              material or functionality through our website, even if we are
              advised of the possibility of such damages. Certain jurisdictions
              do not allow the limitation of liability or the exclusion or
              limitation of certain damages. In such jurisdictions, some or all
              of the above disclaimers, exclusions, or limitations, may not
              apply to you and our liability will be limited to the maximum
              extent permitted by law.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Indemnification</h2>
            <div class="leading-8">
              You agree to defend and indemnify us, and hold us and our
              affiliates harmless, and our and their respective directors,
              officers, agents, contractors, and employees against any losses,
              liabilities, claims, expenses (including legal fees) in any way
              arising from, related to or in connection with your use of our
              website and services, your violation of the Terms, or the posting
              or transmission of any materials on or through the website by you,
              including but not limited to, any third party claim that any
              information or materials provided by you infringe upon any third
              party proprietary rights.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Entire Agreement</h2>
            <div class="leading-8">
              The Terms and any documents expressly referred to in them
              represent the entire agreement between you and us in relation to
              the subject matter of the Terms and supersede any prior agreement,
              understanding or arrangement between you and us, whether oral or
              in writing. Both you and we acknowledge that, in entering into
              these Terms, neither you nor we have relied on any representation,
              undertaking or promise given by the other or implied from anything
              said or written between you and us prior to such Terms, except as
              expressly stated in the Terms.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Data Protection</h2>
            <div class="leading-8">
              Any personal information collected in relation to the use of this
              website will be held and used in accordant with our Privacy
              Policy, which is available on our Site.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Applicable Law and Jurisdiction
            </h2>
            <div class="leading-8">
              These Terms and Conditions of Use shall be interpreted and
              governed by the laws in force in the Federal Republic of Nigeria.
              Subject to the Arbitration section below, each party hereby agrees
              to submit to the jurisdiction of the courts of Nigeria and to
              waive any objections based upon venue.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Arbitration</h2>
            <div class="leading-8">
              Any controversy, claim or dispute arising out of or relating to
              these Terms and Conditions of Use will be referred to and finally
              settled by private and confidential binding arbitration before a
              single arbitrator held in Nigeria in English and governed by
              Nigeria law pursuant to the Arbitration and Conciliation Act Cap
              A18 Laws of the Federation of Nigeria 2004, as amended, replaced
              or re-enacted from time to time. The arbitrator shall be a person
              who is legally trained and who has experience in the information
              technology field in Nigeria and is independent of either party.
              Notwithstanding the foregoing, the Site reserves the right to
              pursue the protection of intellectual property rights and
              confidential information through injunctive or other equitable
              relief through the courts.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Termination</h2>
            <div class="leading-8">
              In addition to any other legal or equitable remedies, we may,
              without prior notice to you, immediately terminate the Terms and
              Conditions of Use or revoke any or all of your rights granted
              under the Terms and Conditions of Use. Upon any termination of
              this Agreement, you shall immediately cease all access to and use
              of the Site and we shall, in addition to any other legal or
              equitable remedies, immediately revoke all password(s) and account
              identification issued to you and deny your access to and use of
              this Site in whole or in part. Any termination of this agreement
              shall not affect the respective rights and obligations (including
              without limitation, payment obligations) of the parties arising
              before the date of termination. You furthermore agree that the
              Site shall not be liable to you or to any other person as a result
              of any such suspension or termination.
              <p>
                If you are dissatisfied with the Site or with any terms,
                conditions, rules, policies, guidelines, or practices of
                Credpal.com in operating the Site, your sole, and exclusive
                remedy is to discontinue using the Site.
              </p>
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Miscellaneous Provisions
            </h2>
            <div class="leading-8">
              You agree that all agreements, notices, disclosures and other
              communications that we provide to you electronically satisfy any
              legal requirement that such communications be in writing.
              Assigning or sub-contracting any of your rights or obligations
              under these Terms and Conditions of Use to any third party is
              prohibited unless agreed upon in writing by the seller.
              <p>
                We reserve the right to transfer, assign or subcontract the
                benefit of the whole or part of any rights or obligations under
                these Terms and Conditions of Use to any third party.
              </p>
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Waiver</h2>
            <div class="leading-8">
              Our failure to exercise or enforce any right or provision of the
              Terms will not constitute a waiver of such right or provision. A
              waiver by us of any default will not constitute a waiver of any
              subsequent default. No waiver by us is effective unless it is
              communicated to you in writing.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Headings</h2>
            <div class="leading-8">
              Any headings and titles herein are for convenience only.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">Severability</h2>
            <div class="leading-8">
              If any of the provisions of the Terms are determined by any
              competent authority to be invalid, unlawful or unenforceable, such
              provision will to that extent be severed from the remaining Terms,
              which will continue to be valid and enforceable to the fullest
              extent permitted by law.
            </div>
          </div>
          <div class="text-justify mb-10">
            <h2 class="text-2xl font-bold">
              Questions or Concerns
            </h2>
            <div class="leading-8">
              Please send all questions, comments, and feedback to us at
              <a
                href="mailto:hello@credpal.com"
                target="_blank"
                class="faq-contact-btn text-white p-5"
              >
                hello@credpal.com </a
              >.
            </div>
          </div>
        </div>
      </div>
    </section>

    <foot />
  </div>
</template>

<script>
export default {
  name: "Terms",
  components: {
    foot: require("./footer/Index").default,
  },
  data() {
    return {
      logo: require("@/assets/logo-blue.svg"),
    };
  },
  computed: {
    checkAuth() {
      return this.$store.state.session.loggedIn;
    },
  },
};
</script>

<style scoped>
header {
  background-color: #f3f5fc;
}
</style>
